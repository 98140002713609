/* You can add global styles to this file, and also import other style files */

/*================================================
Default CSS
=================================================*/
$main-color: #8e582c;
$white-color: #ffffff;
$black-color: #000000;
$paragraph-color: #828893;
$font-family: 'Open Sans', sans-serif;
$font-family2: 'PT Sans', sans-serif;
$font-size: 15px;
$transition: .5s;

body {
    padding: 0;
    margin: 0;
    font: {
        size: $font-size;
        family: $font-family;
    };
}
a {
    text-decoration: none !important;
    transition: $transition;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: $black-color;
    margin-bottom: 15px;
    font: {
        family: $font-family2;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
p {
    line-height: 1.8;
    margin-bottom: 15px;
    color: $paragraph-color;

    &:last-child {
        margin-bottom: 0;
    }
}
.ml-auto {
    margin-left: auto;
}
img {
    max-width: 100%;
    height: auto;
}
.mt-30 {
    margin-top: 30px;
}
.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    }
}
:focus {
    outline: 0 !important;
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
/*btn btn-primary*/
.btn {
    color: $white-color;
    box-shadow: 0 8px 6px -6px rgba(50,51,51,.4);
    border: none;
    padding: 15px 30px;
    border-radius: 0;
    transition: $transition;
    font: {
        weight: 600;
        size: $font-size;
    };
}
.btn-primary {
    color: $white-color;
    background: $black-color;
    position: relative;
    z-index: 1;

    &::before, &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 51%;
        background: $main-color;
        height: 100%;
        z-index: -1;
        transition: $transition;
    }
    &::before {
        left: 0;
    }
    &::after {
        right: 0;
    }
    &:hover::before, &:hover::after, &:focus::before, &:focus::after {
        width: 0;
    }
    &.focus, &:focus {
        box-shadow: unset;
        color: $white-color;
    }
    &:hover, &:focus {
        background: $black-color;
        color: $white-color;
        box-shadow: unset;
    }
    &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus {
        box-shadow: unset;
    }
    &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active {
        background: $black-color;
        color: $white-color;
    }
    &.disabled, &:disabled {
        opacity: 1;
        background: $black-color;
    }
}
/*form-control*/
.form-control {
    height: 50px;
    padding: 15px;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #eeeeee;
    border-radius: 0;
    transition: $transition;

    &:focus {
        box-shadow: unset;
        border-color: $main-color;
    }
}
/*section-title*/
.section-title {
    text-align: center;
    position: relative;
    z-index: 1;
    margin: {
        bottom: 60px;
        top: -5px;
    };
    span {
        text-transform: uppercase;
        display: block;
        letter-spacing: .5px;
        margin-bottom: 12px;
        color: $main-color;
        font: {
            size: 14px;
            weight: 700;
        };
    }
    h2 {
        margin-bottom: 0;
        font: {
            size: 35px;
            weight: 500;
        }
        b {
            font-weight: 600 !important;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, $main-color 80%);
            background-size: 90px 1.1em !important;
        }
    }
    p {
        max-width: 520px;
        margin: 15px auto 0;
    }
}

/*================================================
Preloader CSS
=================================================*/
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    overflow: hidden;
    background: $black-color;

    .loader {
        display: block;
        position: relative;
        left: 50%;
        top: 50%;
        width: 110px;
        height: 110px;
        margin: -80px 0 0 -75px;
        border-radius: 50%;
        border: 7px solid transparent;
        border-top-color: $main-color;
        animation: spin 1.7s linear infinite;
        z-index: 11;

        &::before {
            content: "";
            position: absolute;
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            border-radius: 50%;
            border: 7px solid transparent;
            border-top-color: $white-color;
            animation: spin-reverse .6s linear infinite;
        }
        &::after {
            content: "";
            position: absolute;
            top: 15px;
            left: 15px;
            right: 15px;
            bottom: 15px;
            border-radius: 50%;
            border: 7px solid transparent;
            border-top-color: $main-color;
            animation: spin 1s linear infinite;
        }
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes spin-reverse {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

/*================================================
Navbar CSS
=================================================*/
.navbar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9;
    padding: 25px 0 0;
    height: auto;

    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 15px 0;
        z-index: 999;
        box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.1);
        background-color: $white-color !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }
}
.bg-light {
    background-color: transparent !important;
}
.navbar-brand {
    img {
        &:last-child {
            display: none;
        }
    }
}
.navbar-light {
    .navbar-nav {
        .nav-item {
            padding: {
                right: 13px;
                left: 13px;
            }
            .nav-link {
                color: $white-color;
                cursor: pointer;
                padding: 0;
                position: relative;
                font: {
                    size: 15px;
                    weight: 600;
                }
                &::before {
                    content: '';
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    background: $main-color;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    opacity: 0;
                    visibility: hidden;
                    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                    bottom: 0;
                    transition: $transition;
                }
                &:hover, &.active {
                    color: $main-color !important;

                    &::before {
                        opacity: 1;
                        bottom: -13px;
                        visibility: visible;
                    }
                }
            }
            &:last-child {
                padding-right: 0;
            }
            &:first-child {
                padding-left: 0;
            }
            &.active {
                .nav-link {
                    color: $main-color;

                    &::before {
                        opacity: 1;
                        bottom: -13px;
                        visibility: visible;
                    }
                }
            }
        }
    }
    &.is-sticky {
        .navbar-brand {
            img {
                &:last-child {
                    display: block;
                }
                &:first-child {
                    display: none;
                }
            }
        }
        .navbar-nav {
            .nav-item {
                .nav-link {
                    color: $black-color;

                    &::before {
                        bottom: auto;
                        top: -10px;
                    }
                    &:hover, &.active {
                        color: $main-color;
                    }
                }
                &.active {
                    .nav-link {
                        color: $main-color;
                    }
                }
            }
        }
    }
}
.navbar-light {
    .navbar-toggler {
        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        }
    }
    &.is-sticky {
        .navbar-toggler {
            .navbar-toggler-icon {
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
            }
        }
    }
}

// Mobile and iPad Navbar
@media only screen and (max-width: 991px) {

    .navbar {
        padding: {
            top: 15px;
            bottom: 15px;
        };
    }
    .navbar-expand-lg {
        > .container {
            padding: {
                left: 15px;
                right: 15px;
            };
        }
    }
    .navbar-light {
        .navbar-toggler {
            border-color: $white-color;
            box-shadow: unset;
            border-radius: 0;
        }
        .navbar-nav {
            background-color: $white-color;
            padding: 10px 20px;
            margin-top: 10px;

            .nav-item {
                padding: {
                    left: 0;
                    right: 0;
                    top: 10px;
                    bottom: 10px;
                };
                .nav-link {
                    color: $black-color;
                    display: block;

                    &.active, &:hover {
                        color: $main-color;
                    }
                    &.active {
                        &::before {
                            bottom: 50%;
                        }
                    }
                    &::before {
                        margin: {
                            bottom: -5px;
                            right: 0;
                        };
                    }
                }
            }
        }
        &.is-sticky {
            padding: {
                top: 15px;
                bottom: 15px;
            };
            .navbar-toggler {
                border-color: $black-color;
                box-shadow: unset;
            }
            .navbar-nav {
                border: 1px solid #eeeeee;

                .nav-item {
                    .nav-link {
                        &.active {
                            &::before {
                                top: auto;
                                bottom: 50%;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

}

/*================================================
Main Banner CSS
=================================================*/
.main-banner {
    height: 100vh;
    position: relative;
    z-index: 1;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: rgba(13, 15, 20, .55);
    }
}
.video-banner {
    overflow: hidden;

    .video-background {
        position: absolute;
        left: 0;
        top: 0;
        width: auto;
        height: auto;
        min-height: 100%;
        min-width: 100%;
        z-index: -2;
    }
}
.ripple-effect {
    &.main-banner {
        &::before {
            z-index: 1;
        }
        .main-banner-content {
            position: relative;
            z-index: 2;
        }
    }
}
.item-bg1 {
    background: {
        image: url(assets/img/main-banner1.jpg);
    }
}
.item-bg2 {
    background: {
        image: url(assets/img/main-banner-lechoneria1.jpg);
    }
}
.item-bg3 {
    background: {
        image: url(assets/img/main-banner3.jpg);
    }
}
.item-bg4 {
    background: {
        image: url(assets/img/main-banner4.jpg);
    }
}
.item-bg5 {
    background: {
        image: url(assets/img/main-banner5.jpg);
    }
}
.item-bg6 {
    background: {
        image: url(assets/img/main-banner6.jpg);
    }
}
.item-bg7 {
    background: {
        image: url(assets/img/main-banner7.jpg);
    }
}
.item-bg8 {
    background: {
        image: url(assets/img/main-banner8.jpg);
    }
}
.main-banner-content {
    max-width: 700px;
    position: relative;
    z-index: 3;
    margin: 60px 0 0;

    &.text-center {
        margin: 60px auto 0;

        p {
            margin: {
                left: auto;
                right: auto;
            };
        }
    }
    span {
        color: $white-color;
        text-transform: uppercase;
        display: block;
        letter-spacing: 2px;
        opacity: .88;
        font: {
            weight: 500;
            size: 16px;
        };
    }
    h1 {
        color: $white-color;
        margin: 20px 0 25px;
        font: {
            size: 55px;
            family: $font-family;
            weight: 300;
        };
        b {
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, $main-color 80%);
            background-size: 100px 1.2em !important;
            font: {
                weight: 700;
                family: $font-family2;
            };
        }
        .typewrite {
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, $main-color 80%);
            background-size: 100px 1em !important;
            display: inline-block;
            font: {
                weight: 700;
                family: $font-family2;
            };
            span {
                text-transform: capitalize;
                margin: 0;
                opacity: 1;
                display: inline;
                letter-spacing: 0;
                font: {
                    size: 55px;
                };
            }
        }
    }
    p {
        color: $white-color;
        margin-bottom: 30px;
        max-width: 580px;
        opacity: .88;
        font-size: 16.5px;
    }
    .btn-box {
        span {
            display: inline-block;
            letter-spacing: 0;
            margin: 0 15px;
            font: {
                weight: 700;
                size: 14px;
            };
        }
        .video-btn {
            color: #dddddd;
            display: inline-block;
            font-weight: 600;

            i {
                width: 50px;
                display: inline-block;
                height: 50px;
                line-height: 51px;
                text-align: center;
                background-color: $white-color;
                border-radius: 50%;
                margin-right: 5px;
                z-index: 1;
                color: $main-color;
                position: relative;
                transition: $transition;

                &::after {
                    z-index: -1;
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 50px;
                    height: 50px;
                    animation: ripple 1.6s ease-out infinite;
                    opacity: 0;
                    border-radius: 50%;
                    background: rgba(255, 255, 255, .5);
                }
            }
            &:hover, &:focus {
                color: $white-color;

                i {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}
.main-banner {
    &.main-banner-with-form {
        height: 840px;

        .main-banner-content {
            margin-top: 0;
            max-width: 100%;
        }
    }
}
.banner-form {
    background: $white-color;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 40px 25px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        background: $white-color;
        width: 96%;
        opacity: .62;
        height: 50%;
        bottom: -10px;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 3px;
    }
    form {
        .form-control {
            border: 1px solid #eeeeee;
            font-size: 14px;
            border-radius: 2px;
        }
        label {
            color: $paragraph-color;
            margin-bottom: 8px;
            font-size: 15px;
        }
        select.form-control {
            height: 50px;
            cursor: pointer;
        }
        .btn {
            margin-top: 10px;
        }
    }
}
.banner-video {
    position: relative;
    text-align: center;
    border: 5px solid $white-color;
    box-shadow: 0px 10px 30px rgba(0,0,0,.05);
    overflow: hidden;

    &::before {
        background: linear-gradient(to bottom, #0975bc, #0d5b93, #0d426d, #092b48, #041527);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        content: '';
        opacity: .70;
        z-index: 1;
    }
    img {
        transition: $transition;
    }
    .video-btn {
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 2;
        right: 0;
        margin: 0 auto;
        width: 70px;
        height: 70px;
        line-height: 72px;
        border-radius: 50%;
        margin-top: -30px;
        color: $white-color;
        font-size: 20px;
        background-color: $main-color;
        transition: $transition;

        &::before {
            z-index: -1;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 70px;
            height: 70px;
            animation: ripple 1.6s ease-out infinite;
            opacity: 0;
            border-radius: 50%;
            background: rgba(148, 107, 255, .5);
        }
        &:hover, &:focus {
            color: $main-color;
            background-color: $white-color;
        }
    }
    &:hover, &:focus {
        img {
            transform: scale(1.4);
        }
    }
}
.text-animation-home {
    .main-banner-content {
        h1 {
            margin-bottom: 15px;
        }
    }
}
#particles-js {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
#particles-js-bubble {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
#particles-js-star {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
#particles-js-big-bubble {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
#particles-js-small-bubble {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.slideshow-banner {
    .slideshow {
        z-index: -2;
        list-style-type: none;
        padding: 0;
        margin: 0;
    
        li {
            span {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: -2;
                animation: imageAnimation 24s linear infinite 0s;
                background: {
                    size: cover;
                    position: center center;
                    repeat: no-repeat;
                };
            }
            &:nth-child(1) span {
                background-image: url(assets/img/main-banner8.jpg);
            }
            &:nth-child(2) span {
                background-image: url(assets/img/main-banner2.jpg);
                animation-delay: 6s;
            }
            &:nth-child(3) span {
                background-image: url(assets/img/main-banner5.jpg);
                animation-delay: 12s;
            }
            &:nth-child(4) span {
                background-image: url(assets/img/main-banner7.jpg);
                animation-delay: 18s;
            }
        }
    }
}
.clip-path-banner {
    clip-path: polygon(0 0, 100% 0, 100% 90%, 70% 100%, 0 90%);

    .main-banner-content {
        margin-top: 0;
    }
}
.clip-path-banner-two {
    clip-path: polygon(0 0, 100% 0, 100% 90%, 30% 100%, 0 90%);

    .main-banner-content {
        margin-top: 0;
    }
}
.creative-banner {
    .creative-shape {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
    .main-banner-content {
        margin-top: 0;
    }
}
.gradient-banner {
    &.main-banner {
        &::before {
            background: linear-gradient(to left bottom, #292e49, #303d55, #394b61, #455a6b, #536976);
            opacity: .90;
        }
    }
}
.home-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            line-height: .01;

            [class*=owl-] {
                position: absolute;
                left: 50px;
                top: 50%;
                width: 60px;
                height: 60px;
                outline: 0;
                box-shadow: unset;
                opacity: 0;
                visibility: hidden;
                line-height: 60px;
                background-color: $black-color;
                border-radius: 0;
                color: $white-color;
                transition: $transition;
                opacity: .7;
                margin-top: -50px;
                font: {
                    size: 28px;
                }
                &.owl-next {
                    left: auto;
                    right: 50px;
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                    opacity: 1;
                    outline: 0;
                    box-shadow: unset;
                }
            }
        }
        &:hover, &:focus {
            .owl-nav {
                [class*=owl-] {
                    opacity: 1;
                    left: 10px;
                    visibility: visible;

                    &.owl-next {
                        left: auto;
                        right: 10px;
                    }
                }
            }
        }
    }
}
@keyframes ripple {
    0%, 35% {
        transform: scale(0);
        opacity: 1;
    }
    50% {
        transform: scale(1.5);
        opacity: 0.8;
    }
    100% {
        opacity: 0;
        transform: scale(2);
    }
}
@keyframes imageAnimation {
    0% {
        opacity: 0;
        animation-timing-function: ease-in;
    }
    12.5% {
        opacity: 1;
        animation-timing-function: ease-out;
    }
    25% {
        opacity: 1;
    }
    37.5% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
.no-cssanimations {
    .slideshow {
        li {
            span {
                opacity: 1;
            }
        }
    }
}
.shape1 {
    position: absolute;
    left: 70px;
    bottom: 70px;
    z-index: -1;
    animation: movebounce 5s linear infinite;
    opacity: .6;
}
.shape2 {
    position: absolute;
    right: 70px;
    bottom: 70px;
    z-index: -1;
    opacity: .6;
}
.shape3 {
    position: absolute;
    left: 70px;
    top: 70px;
    z-index: -1;
    opacity: .6;
}
.shape4 {
    position: absolute;
    right: 100px;
    top: 100px;
    z-index: -1;
    opacity: .6;
    animation: movebounce 5s linear infinite;
}
.rotateme {
    animation-name: rotateme;
    animation-duration: 40s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes rotateme {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes movebounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}

/*================================================
Features CSS
=================================================*/
.features-area {
    position: relative;
    padding-bottom: 70px;
    z-index: 1;

    &.bg-image {
        background: {
            image: url(assets/img/bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
    }
}
.single-features {
    background-color: $white-color;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    transition: $transition;
    padding: 35px 25px;
    text-align: center;
    margin-bottom: 30px;
    transition: $transition;

    .icon {
        transition: $transition;
        color: $main-color;
        font: {
            size: 45px;
        };
    }
    h3 {
        transition: $transition;
        font: {
            size: 22px;
            weight: 600;
        };
        margin: {
            top: 15px;
            bottom: 12px;
        };
    }
    p {
        transition: $transition;
    }
    &:hover, &:focus {
        background-color: $main-color;
        transform: translateY(-10px);

        .icon {
            color: $white-color;
        }
        h3 {
            color: $white-color;
        }
        p {
            color: $white-color;
            opacity: .88;
        }
    }
    &.active {
        background-color: $main-color;

        .icon {
            color: $white-color;
        }
        h3 {
            color: $white-color;
        }
        p {
            color: $white-color;
            opacity: .88;
        }
    }
}

/*================================================
About CSS
=================================================*/
.about-area {
    position: relative;
    z-index: 1;

    &.bg-image {
        background: {
            image: url(assets/img/bg2.jpg);
            position: center center;
            size: contain;
            repeat: no-repeat;
        };
    }
    &.bg-image2 {
        background: {
            image: url(assets/img/bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
    }
}
.about-image {
    .col-lg-6 {
        &:first-child {
            .image {
                img {
                    border-radius: 25px 0 0 0;
                }
            }
        }
        &:nth-child(2) {
            .image {
                img {
                    border-radius: 0 25px 0 0;
                }
            }
        }
        &:nth-child(3) {
            .image {
                img {
                    border-radius: 0 0 0 25px;
                }
            }
        }
        &:last-child {
            .image {
                img {
                    border-radius: 0 0 25px 0;
                }
            }
        }
    }
    .image {
        img {
            box-shadow: 0px 10px 20px 0px rgba(130,136,147,0.15);
            transition: $transition;

            &:hover, &:focus {
                transform: translateY(-10px);
            }
        }
    }
}
.about-content {
    padding-left: 10px;

    span {
        text-transform: uppercase;
        display: block;
        letter-spacing: .5px;
        margin-bottom: 10px;
        color: $main-color;
        font: {
            size: 14px;
            weight: 700;
        };
    }
    h2 {
        margin-bottom: 15px;
        font: {
            size: 33px;
            weight: 500;
        };
        b {
            font-weight: 600 !important;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, $main-color 80%);
            background-size: 90px 1.1em !important;
        }
    }
    ul {
        padding: 0;
        margin: 15px 0 0;
        list-style-type: none;

        li {
            color: $paragraph-color;
            margin-bottom: 10px;
            font-weight: 600;
            padding-left: 17px;
            position: relative;

            &:last-child {
                margin-bottom: 0;
            }
            &::before {
                content: '';
                top: 6px;
                left: 0;
                position: absolute;
                width: 10px;
                height: 10px;
                background: $main-color;
                clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            }
        }
    }
    p {
        margin: {
            bottom: 0;
            top: 15px;
        };
    }
}

/*================================================
CTA CSS
=================================================*/
.cta-area {
    position: relative;
    z-index: 1;
    background: {
        position: center center;
        size: cover;
        attachment: fixed;
        repeat: no-repeat;
        image: url(assets/img/cta-bg.jpg);
    };
    padding: {
        top: 70px;
        bottom: 70px;
    };
    &::before {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $main-color;
        position: absolute;
        content: '';
        z-index: -1;
        opacity: .85;
    }
    .cta-content {
        h3 {
            text-transform: capitalize;
            margin-bottom: 0;
            color: $white-color;
            font: {
                size: 34px;
                weight: 500;
            };
            b {
                font-weight: 600 !important;
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, $black-color 80%);
                background-size: 90px 1.1em !important;
            }
        }
        span {
            text-transform: capitalize;
            display: block;
            color: $white-color;
            margin-top: 12px;
            opacity: .88;
            font: {
                size: 14px;
                weight: 600;
            };
        }
    }
    .btn {
        color: $black-color;

        &:hover, &:focus {
            color: $white-color;
        }
        &::before, &::after {
            background: $white-color;
        }
    }
}

/*================================================
Services CSS
=================================================*/
.services-area {
    position: relative;
    padding-bottom: 70px;
    z-index: 1;
}
.single-services {
    margin-bottom: 30px;
    transition: $transition;
    background: $white-color;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    
    .services-image {
        overflow: hidden;
        position: relative;
        
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: $black-color;
            z-index: 2;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
        }
        img {
            transition: $transition;
        }
        .icon {
            position: absolute;
            left: 0;
            top: 50%;
            z-index: 3;
            right: 0;
            margin: 0 auto;
            text-align: center;
            transform: translateY(-40%);
            width: 60px;
            opacity: 0;
            visibility: hidden;
            height: 60px;
            line-height: 58px;
            background: $main-color;
            color: $white-color;
            border-radius: 50%;
            font-size: 22px;
            transition: $transition;
        }
    }
    .services-content {
        padding: 30px 20px;

        h3 {
            transition: $transition;
            font: {
                size: 22px;
                weight: 600;
            };
            margin: {
                bottom: 12px;
            };
        }
        p {
            transition: $transition;
        }
    }
    &:hover, &:focus {
        background: $main-color;

        .services-image {
            img {
                transform: scale(1.4);
            }
            &::before {
                opacity: .70;
                visibility: visible;
            }
            .icon {
                transform: translateY(-50%);
                opacity: 1;
                visibility: visible;
            }
        }
        .services-content {
            h3, p {
                color: $white-color;
            }
        }
    }
}

/*================================================
Who We Are CSS
=================================================*/
.who-we-are {
    position: relative;
    padding-bottom: 70px;
    z-index: 1;

    &.bg-image {
        background: {
            image: url(assets/img/bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }
    }
    .section-title {
        text-align: left;
        margin-bottom: 0;

        p {
            margin: {
                bottom: 25px;
                left: 0;
                right: 0;
            }
        }
    }
    .content {
        p {
            margin-bottom: 0;
        }
        ul {
            padding: 0;
            list-style-type: none;
            margin: 10px 0 0;
            overflow: hidden;

            li {
                color: $paragraph-color;
                margin-top: 10px;
                float: left;
                width: 45%;
                padding-left: 17px;
                font-weight: 600;
                position: relative;

                &::before {
                    content: '';
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    background: $main-color;
                    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                }
            }
        }
    }
}

/*================================================
Skill CSS
=================================================*/
.skill-image {
    width: 100%;
    height: 100%;
    background: {
        image: url(assets/img/skill-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    img {
        display: none;
    }
}
.skill-content {
    background-color: $main-color;
    padding: {
        left: 100px;
        right: 100px;
    };
    .section-title {
        text-align: left;
        margin-bottom: 35px;

        span {
            color: $white-color;
            opacity: .95;
        }
        h2 {
            color: $white-color;

            b {
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, $black-color 80%);
            }
        }
        p {
            color: $white-color;
            opacity: .95;
            margin: {
                left: 0;
                right: 0;
            };
        }
    }
    .skills {
        position: relative;

        .skill-item {
            position: relative;
            margin-bottom: 25px;
        
            .skill-header {
                position: relative;
                margin-bottom: 10px;

                .skill-title {
                    margin-bottom: 0;
                    color: $white-color;
                    font: {
                        size: 16px;
                        weight: 600;
                    };
                }
                .skill-percentage {
                    position: absolute;
                    right: 0;
                    top: 1px;
                    color: $white-color;
                    font: {
                        size: 15px;
                        weight: 600;
                    };
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
            .skill-bar {
                position: relative;
                width: 100%;

                .bar-inner {
                    position: relative;
                    width: 100%;
                    border-radius: 30px;
                    border: {
                        top: 3px solid $black-color;
                        bottom: 3px solid $black-color;
                    };
                    .bar {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 0;
                        height: 6px;
                        background: $white-color;
                        transition: all 2000ms ease;
                        border-radius: 30px;
                        margin-top: -3px;
                    }
                }
            }
        }
    }
}

/*================================================
Why We Different CSS
=================================================*/
.why-we-different {
    position: relative;
    z-index: 1;

    &.bg-image {
        background: {
            image: url(assets/img/bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
    }
}
.tab {
    .tabs {
        list-style-type: none;
        padding: 0;
        margin: 0 0 50px;
        text-align: center;

        li {
            display: inline-block;
            margin: 0 5px;

            a {
                color: $black-color;
                padding: 15px 30px;
                background: transparent;
                transition: $transition;
                display: block;
                border: 1px solid #eeeeee;
                border-radius: 5px;
                text-transform: capitalize;
                font-weight: 600;

                &:hover, &:focus {
                    background: $main-color;
                    color: $white-color;
                    border-color: $main-color;
                }
            }
            &.current {
                a {
                    background: $main-color;
                    color: $white-color;
                    border-color: $main-color;
                }
            }
        }
    }
    .tabs_item {
        display: none;

        &:first-child {
            display: block;
        }
        .tabs_item_content {
            h3 {
                margin-bottom: 12px;
                font: {
                    size: 22px;
                    weight: 600;
                };
            }
            p {
                margin-bottom: 0;
            }
            ul {
                padding: 0;
                list-style-type: none;
                margin: 20px 0;
                overflow: hidden;
    
                li {
                    color: $paragraph-color;
                    margin-bottom: 10px;
                    float: left;
                    width: 45%;
                    padding-left: 17px;
                    font-weight: 600;
                    position: relative;
        
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &::before {
                        content: '';
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        background: $main-color;
                        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                    }
                }
            }
        }
    }
}

/*================================================
Portfolio CSS
=================================================*/
.portfolio-area {
    position: relative;
    padding-bottom: 70px;
    z-index: 1;

    &.bg-image {
        background: {
            image: url(assets/img/bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
    }
}
.shorting-menu {
    text-align: center;
    margin-bottom: 40px;

    .filter {
        border: none;
        margin: 0 15px;
        position: relative;
        font-weight: 600;
        outline: 0 !important;
        box-shadow: unset !important;
        background: transparent;
        transition: $transition;
        z-index: 1;

        &::before {
            clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            background: #bbbbbb;
            content: '';
            position: absolute;
            left: -22px;
            top: 50%;
            width: 10px;
            margin-top: -4px;
            height: 10px;
        }
        &::after {
            position: absolute;
            content: '';
            width: 12px;
            height: 2px;
            z-index: -1;
            background: $black-color;
            left: 3px;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            bottom: 8px;
            transform: rotate(0);
        }
        &:first-child {
            &::before {
                display: none;
            }
        }
        &:hover, &:focus, &.mixitup-control-active {
            color: $main-color;

            &::after {
                opacity: 1;
                transform: rotate(45deg);
                visibility: visible;
            }
        }
    }
}
.single-work {
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;

    a {
        &.popup-btn {
            position: absolute;
            width: 100%;
            display: block;
            height: 100%;
            z-index: 1;
        }
    }
    .work-image {
        position: relative;

        img {
            transition: $transition;
        }
        .work-overlay {
            position: absolute;
            overflow: hidden;
            top: 10px;
            left: 10px;
            right: 10px;
            bottom: 10px;

            &::before {
                content: '';
                transition: $transition;
                position: absolute;
                width: 100%;
                bottom: 0;
                height: 50%;
                background: $main-color;
                right: -100%;
            }
            &::after {
                content: '';
                transition: $transition;
                position: absolute;
                background: $main-color;
                top: 0;
                height: 50%;
                width: 100%;
                left: -100%;
            }
            h3 {
                color: transparent;
                overflow: hidden;
                position: absolute;
                top: 30px;
                text-align: center;
                right: 25px;
                margin: 0;
                transition: $transition;
                z-index: 2;
                font: {
                    size: 22px;
                    weight: 600;
                };
                &::before {
                    content: '';
                    background: $black-color;
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    transition: $transition;
                    left: 100%;
                    top: 0;
                    right: auto;
                }
            }
            span {
                color: transparent;
                overflow: hidden;
                z-index: 2;
                position: absolute;
                top: 62px;
                display: block;
                right: 25px;
                transition: $transition;
                text-align: center;
                font: {
                    weight: 600;
                };
                &::before {
                    content: '';
                    background: $white-color;
                    width: 100%;
                    top: 0;
                    bottom: 0;
                    position: absolute;
                    transition: $transition;
                    right: 100%;
                }
            }
        }
    }
    &:hover, &:focus {
        .work-image {
            img {
                transform: scale(1.3);
            }
            .work-overlay {
                &::before {
                    right: 0;
                    opacity: 1;
                    width: 100%;
                    height: 50%;
                    transition: all .8s;
                }
                &::after {
                    left: 0;
                    opacity: 1;
                    transition: all .8s;
                }
                span {
                    color: $white-color;

                    &::before {
                        right: -100%;
                    }
                }
                h3 {
                    color: $white-color;

                    &::before {
                        left: -100%;
                    }
                }
            }
        }
    }
}

/*================================================
Fun Facts CSS
=================================================*/
.funfacts-area {
    position: relative;
    z-index: 1;

    &.bg-image {
        background: {
            image: url(assets/img/overlay.png);
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
    }
    .section-title {
        margin-bottom: 0;
        text-align: left;

        p {
            margin: {
                bottom: 25px;
                left: 0;
                right: 0;
            };
        }
    }
}
.counter-wrap {
    .single-counter {
        padding-left: 125px;
        position: relative;
        margin: {
            left: 40px;
            bottom: 30px;
        };
        h2 {
            position: absolute;
            left: 0;
            top: 0;
            color: $main-color;
            margin-bottom: 0;
            font: {
                size: 45px;
                weight: 600;
            };
        }
        h3 {
            margin-bottom: 12px;
            font: {
                size: 22px;
                weight: 600;
            };
        }
        p {
            margin-bottom: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .odometer-formatting-mark {
        display: none;
    }
}
/*funfacts-style-two*/
.funfacts-area-two {
    position: relative;
    z-index: 1;
    background: {
        position: center center;
        size: cover;
        attachment: fixed;
        repeat: no-repeat;
        image: url(assets/img/funfacts-bg.jpg);
    };
    &::before {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $black-color;
        position: absolute;
        content: '';
        opacity: .70;
    }
}
.single-funfact {
    text-align: center;

    i {
        color: $main-color;
        font-size: 40px;
    }
    .odometer-formatting-mark {
        display: none;
    }
    h3 {
        margin: 25px 0 6px;
        display: block !important;
        color: $white-color;
        font: {
            size: 35px;
            weight: 700;
        };
    }
    p {
        color: $white-color;
        opacity: .88;
    }
}

/*================================================
Team CSS
=================================================*/
.team-area {
    position: relative;
    z-index: 1;

    &.bg-image {
        background: {
            image: url(assets/img/bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
    }
}
.team-box {
    overflow: hidden;
    position: relative;

    &::before {
        content: "";
        display: block;
        border: 10px solid rgba(255, 255, 255, 0.3);
        position: absolute;
        top: 5px;
        left: 5px;
        bottom: 5px;
        right: 5px;
        opacity: 1;
        z-index: 2;
        transition: all 1s ease 0s;
    }
    &::after {
        content: "";
        display: block;
        border: 5px solid rgba(255, 255, 255, 0.7);
        position: absolute;
        top: 15px;
        left: 15px;
        bottom: 15px;
        right: 15px;
        opacity: 1;
        z-index: 1;
        transition: all 1s ease 0s;
    }
    img {
        width: auto !important;
        transform: scale(1);
        transition: all 1s ease 0s;
        display: inline-block !important;
    }
    .box-content {
        padding: 20px;
        text-align: center;
        position: absolute;
        top: 45px;
        left: 45px;
        bottom: 45px;
        right: 45px;
        opacity: 1;
        z-index: 2;
        transition: all 0.3s ease 0s;

        .box-inner-content {
            width: 100%;
            padding: 20px;
            opacity: 0;
            background-color: $white-color;
            position: absolute;
            bottom: 0;
            left: 0;
            transition: $transition;

            .title {
                margin-bottom: 0;
                font: {
                    size: 22px;
                    weight: 600;
                };
            }
            .post {
                display: block;
                margin-top: 6px;
                color: $main-color;
            }
            ul {
                padding: 0;
                list-style-type: none;
                margin: 15px 0 0;

                li {
                    display: inline-block;
                    margin: 0 3px;

                    a {
                        width: 33px;
                        height: 33px;
                        line-height: 30px;
                        border: 1px solid #eeeeee;
                        border-radius: 50%;
                        color: $paragraph-color;
                        display: block;
                        font-size: 14px;

                        &:hover, &:focus {
                            background-color: $main-color;
                            color: $white-color;
                            border-color: $main-color;
                        }
                    }
                }
            }
        }
    }
    &:hover, &:focus {
        &::before {
            border: 1px solid rgba(255, 255, 255, 0.18);
        }
        &::after {
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
        }
        img { 
            transform: scale(1.2);
        }
        .box-content {
            top: 6px;
            left: 6px;
            bottom: 6px;
            right: 6px;

            .box-inner-content {
                opacity: 1;
            }
        }
    }
}
.team-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            line-height: .01;

            [class*=owl-] {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                outline: 0;
                margin: 0;
                font-size: 20px;
                box-shadow: unset;
                border-radius: 0;
                transition: $transition;
                width: 40px;
                opacity: 0;
                visibility: hidden;
                height: 40px;
                background-color: $paragraph-color;
                line-height: 40px;
                color: $white-color;

                &.owl-next {
                    left: auto;
                    right: 0;
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
        &:hover, &:focus {
            .owl-nav {
                [class*=owl-] {
                   left: -40px;
                   opacity: 1;
                   visibility: visible;

                   &.owl-next {
                       left: auto;
                       right: -40px;
                   }
                }
            }
        }
    }
}

/*================================================
Story CSS
=================================================*/
.story-area {
    position: relative;
    z-index: 1;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
        image: url(assets/img/story-bg.jpg);
    }
    &::before {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $black-color;
        position: absolute;
        content: '';
        opacity: .70;
    }
}
.story-content {
    max-width: 520px;
    margin: 0 auto;

    .section-title {
        margin-bottom: 0;

        h2 {
            color: $white-color;
            line-height: 48px;
            margin-bottom: 35px;
        }
    }
    .video-btn {
        display: inline-block;

        i {
            width: 70px;
            display: inline-block;
            height: 70px;
            line-height: 72px;
            text-align: center;
            background-color: $white-color;
            border-radius: 50%;
            z-index: 1;
            color: $main-color;
            font-size: 25px;
            position: relative;
            transition: $transition;

            &::after {
                z-index: -1;
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 70px;
                height: 70px;
                animation: ripple 1.6s ease-out infinite;
                opacity: 0;
                border-radius: 50%;
                background: rgba(255, 255, 255, .5);
            }
        }
        &:hover, &:focus {
            i {
                background-color: $main-color;
                color: $white-color;
            }
        }
    }
}

/*================================================
Pricing CSS
=================================================*/
.tab-slider-nav {
	text-align: center;
    margin-bottom: 40px;
    
    .tab-slider-tabs {
        margin: 0;
        padding: 0;
        list-style: none;

        .tab-slider-trigger {
            color: $paragraph-color;
            text-transform: uppercase;
            background-color: $white-color;
            margin: 0 -2px;
            padding: 10px 20px;
            box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
            cursor: pointer;
            border-bottom: 3px solid $main-color;
            display: inline-block;
            transition: $transition;
            font: {
                weight: 600;
                size: 14px;
            };
            &.active, &:hover, &:focus {
                color: $white-color;
                background: $main-color;
            }
        }
    }
}
.pricing-table {
    text-align: center;
    padding-bottom: 35px;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    transition: $transition;

    .pricing-header {
        padding: 25px 20px;
        border-bottom: 1px solid #eeeeee;
        transition: $transition;
        position: relative;
        z-index: 1;
        font: {
            size: 22px;
            weight: 700;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 0;
            background: $main-color;
            z-index: -1;
            transition: $transition;
            opacity: 1;
            visibility: hidden;
        }
    }
    .price {
        color: $main-color;
        font: {
            size: 40px;
            weight: 700;
        };
        margin: {
            top: 25px;
            bottom: 25px;
        };
        sup {
            font-size: 20px;
            top: -15px;
            color: $black-color;
        }
    }
    .pricing-features {
        padding: 0;
        margin: 0 0 35px;
        list-style-type: none;

        li {
            color: $paragraph-color;
            margin-bottom: 13px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &.active, &:hover {
        .pricing-header {
            border-color: $main-color;
            color: $white-color;

            &::before {
                height: 100%;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

/*================================================
Testimonial CSS
=================================================*/
.testimonial-area {
    position: relative;
    z-index: 1;

    &.bg-image {
        background: {
            image: url(assets/img/bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
    }
}
.testimonial-item {
    border-right: 3px solid $black-color;
    border-left: 3px solid $black-color;
    box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.06);
    padding: 30px 20px 30px 125px;
    margin-bottom: 30px;
    background: $white-color;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .client-image {
        display: inline-block;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        top: 30px;
        left: 20px;

        img {
            border-radius: 50%;
            width: auto !important;
            display: inline-block !important;
        }
    }
    .testimonial-content {
        .client-info {
            margin-top: 20px;

            h3 {
                margin-bottom: 0;
                font: {
                    size: 22px;
                    weight: 600;
                };
            }
            span {
                display: block;
                color: $main-color;
                margin-top: 7px;
            }
        }
    }
}
.testimonial-slides {
    &.owl-theme {
        .owl-nav {
            &.disabled {
                + .owl-dots {
                    margin-top: 25px;
                    line-height: .01;
                }
            }
        }
        .owl-dots {
            .owl-dot {
                span {
                    width: 15px;
                    height: 15px;
                    margin: 0 4px;
                    background: transparent;
                    border: 1px solid #dfdfdf;
                    display: block;
                    transition: $transition;
                    border-radius: 50%;
                    position: relative;
                }
                &:hover {    
                    span {
                        background: $main-color;
                        border-color: $main-color;
                    }
                }
                &.active {
                    span {
                        background: $main-color;
                        border-color: $main-color;
                    }
                }
            }
        }
    }
}

/*================================================
Call To Action CSS
=================================================*/
.call-to-action {
    position: relative;
    z-index: 1;
    background: {
        position: center center;
        size: cover;
        attachment: fixed;
        repeat: no-repeat;
        image: url(assets/img/call-to-action-bg.jpg);
    };
    &::before {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $black-color;
        position: absolute;
        content: '';
        opacity: .70;
    }
}
.call-to-action-content {
    max-width: 520px;
    margin: 0 auto;

    .section-title {
        margin-bottom: 0;

        h2 {
            color: $white-color;
        }
    }
    p {
        color: $white-color;
    }
    .btn {
        background-color: $white-color;
        margin-top: 25px;

        &:hover, &:focus, &.active {
            background-color: $white-color;
            color: $black-color;
        }
    }
}

/*================================================
FAQ CSS
=================================================*/
.faq-image {
    width: 100%;
    height: 100%;
    background: {
        image: url(assets/img/faq-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    img {
        display: none;
    }
}
.faq-accordion {
    .accordion-item {
        border-radius: 0 !important;
        display: block;
        margin-bottom: 15px;
        border: none;
        background-color: $white-color;
        box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
    
        .accordion-header {
            padding: 0;
            margin-bottom: 0;
            border: none;
            background: transparent;
    
            &:first-child {
                border-radius: 0;
            }
            .accordion-button {
                display: block;
                overflow: hidden;
                padding: 17px 40px 17px 20px;
                color: $white-color;
                box-shadow: unset;
                background: $main-color;
                position: relative;
                font: {
                    size: $font-size;
                    family: $font-family;
                    weight: 600;
                };
                &:hover {
                    background: $main-color;
                }
                i {
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    font-size: 18px;
                    transform: translateY(-50%) rotate(180deg);
                }
                &.collapsed {
                    color: $black-color;
                    background: transparent;
                    transition: $transition;
    
                    &:hover {
                        color: $white-color;
                        background-color: $main-color;
                    }
                    i {
                        transform: translateY(-50%) rotate(0);
                    }
                }
                &::after {
                    display: none;
                }
            }
        }
        .accordion-body {
            line-height: 1.8;
            background: transparent;
            color: $paragraph-color;
            padding: 15px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

/*================================================
Blog CSS
=================================================*/
.blog-area {
    position: relative;
    z-index: 1;

    &.bg-image {
        background: {
            image: url(assets/img/bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
    }
}
.single-blog-post {
    margin-bottom: 30px;
    background-color: $white-color;
    box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.06);

    .blog-image {
        position: relative;
        overflow: hidden;

        a {
            display: block;

            img {
                transition: $transition;
            }
        }
        .post-tag {
            position: absolute;
            left: 0;
            top: 40px;
            transition: $transition;

            a {
                background: $main-color;
                color: $white-color;
                padding: 9px 28px;
                display: inline-block;
            }
        }
    }
    .blog-post-content {
        padding: 25px;

        .date {
            color: $main-color;
            display: block;
            text-transform: uppercase;
            transition: $transition;
            font: {
                size: 14px;
            };
        }
        h3 {
            line-height: 30px;
            margin: {
                top: 13px;
                bottom: 12px;
            };
            font: {
                size: 22px;
                weight: 600;
            };
            a {
                color: $black-color;
                display: inline-block;

                &:hover, &:focus {
                    color: $main-color;
                }
            }
        }
        p {
            margin-bottom: 18px;
        }
    }
    .read-more-btn {
        position: relative;
        font-weight: 500;
        color: $main-color;

        &:hover {
            color: $main-color;
            letter-spacing: 1px;
        }
    }
    &:hover, &:focus {
        .blog-image {
            a {
                img {
                    transform: scale(1.2);
                }
            }
            .post-tag {
                top: 50px;
    
                a {
                    background: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}
.blog-slides {
    &.owl-theme {
        .owl-nav {
            &.disabled {
                + .owl-dots {
                    margin-top: 25px;
                    line-height: .01;
                }
            }
        }
        .owl-dots {
            .owl-dot {
                span {
                    width: 15px;
                    height: 15px;
                    margin: 0 4px;
                    background: transparent;
                    border: 1px solid #dfdfdf;
                    display: block;
                    transition: $transition;
                    border-radius: 50%;
                    position: relative;
                }
                &:hover, &:focus {
                    outline: 0;
                    box-shadow: unset !important;
    
                    span {
                        background: $main-color;
                        border-color: $main-color;
                    }
                }
                &.active {
                    span {
                        background: $main-color;
                        border-color: $main-color;
                    }
                }
            }
        }
    }
}

/*================================================
Partner CSS
=================================================*/
.partner-area {
    background-color: #f7f7f7;
}
.partner-item {
    background: $white-color;
    text-align: center;
    position: relative;
    z-index: 1;

    &::before, &::after {
        content: '';
        position: absolute;
        z-index: -1;
        width: 0;
        height: 0;
        opacity: 0;
        visibility: hidden;
        transition: $transition;
    }
    &::before {
        left: 0;
        top: 0;
        border: {
            left: 3px solid $main-color;
            top: 3px solid $main-color;
        };
    }
    &::after {
        right: 0;
        bottom: 0;
        border: {
            right: 3px solid $main-color;
            bottom: 3px solid $main-color;
        };
    }
    a {
        display: block;
        padding: 25px 15px;

        img {
            display: inline-block !important;
            width: auto !important;
            transition: $transition;
        }
    }
    &:hover, &:focus {
        &::after, &::before {
            width: 40px;
            height: 40px;
            opacity: 1;
            visibility: visible;
        }
        a {
            img {
                opacity: .66;
                transform: scale(0.9);
            }
        }
    }
}

/*================================================
Subscribe CSS
=================================================*/
.subscribe-area {
    background: {
        image: url(assets/img/pattern.png);
        position: center;
        size: contain;
        repeat: repeat;
    };
}
.newsletter {
	max-width: 750px;
	text-align: center;
	box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
	background: $white-color;
	padding: 35px;
    border-radius: 5px;
    margin: {
        left: auto;
        right: auto;
    };
    h2 {
        text-transform: capitalize;
        margin-bottom: 30px;
        font: {
            size: 35px;
            weight: 500;
        };
        b {
            font-weight: 600 !important;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, $main-color 80%);
            background-size: 90px 1.1em !important;
        }
    }
    form {
        position: relative;

        .form-control {
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
            border: none;
            height: 75px;
            border-radius: 50px;
            padding-left: 20px;
            color: $black-color;
            font: {
                weight: 500;
                size: 16px;
            };
            &::placeholder {
                color: $paragraph-color;
            }
        }
        button {
            position: absolute;
            right: 7px;
            border-radius: 30px;
            top: 7px;
            height: 60px;
            width: 175px;
            background: $main-color;
            border: none;
            outline: 0;
            cursor: pointer;
            transition: $transition;
            color: $white-color;
            font: {
                weight: 600;
                size: 16px;
            }
            &:hover, &:focus {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
}

/*================================================
Contact CSS
=================================================*/
.contact-area {
    position: relative;
    z-index: 1;

    &.bg-image {
        background: {
            image: url(assets/img/bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
    }
}
.contact-info {
	box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
	background: $white-color;
    padding: 30px;
    height: 100%;
    border: {
        top: 3px solid $main-color;
        bottom: 3px solid $main-color;
    };
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            position: relative;
            padding-left: 60px;
            font-weight: 600;
            margin-bottom: 25px;
            color: $paragraph-color;

            &:last-child {
                margin-bottom: 0;
            }
            span {
                display: block;
                margin-bottom: 3px;
                color: $black-color;
                font: {
                    weight: 600;
                    size: 20px;
                };
            }
            a {
                color: $paragraph-color;
                display: inline-block;

                &:hover, &:focus {
                    color: $main-color;
                }
            }
            i {
                position: absolute;
                left: 0;
                top: 50%;
                color: $main-color;
                font-size: 19px;
                background: #f6f6f6;
                transform: translateY(-50%);
                width: 44px;
                height: 44px;
                line-height: 44px;
                text-align: center;
                border-radius: 50%;
                transition: $transition;
            }
            &:hover, &:focus {
                i {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}
.contact-form {
    box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
    background-color: $white-color;
    padding: 30px;
    border: {
        top: 3px solid $main-color;
        bottom: 3px solid $main-color;
    };
    .btn {
        margin-top: 12px;
    }
    textarea.form-control {
        min-height: auto;
        height: auto;
    }
}

/*================================================
Page Title CSS
=================================================*/
.page-title-area {
    position: relative;
    z-index: 1;
    text-align: center;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    padding: {
        top: 200px;
        bottom: 120px;
    };
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(13, 15, 20, 0.75);
        width: 100%;
        height: 100%;
        z-index: -1;
        content: '';
    }
    h2 {
        margin-bottom: 0;
        color: $white-color;
        font: {
            size: 45px;
            weight: 600;
        };
    }
}

/*================================================
Pagination CSS
=================================================*/
.pagination-area {
    margin-top: 30px;

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            a {
                width: 40px;
                height: 40px;
                padding: 0;
                margin: 0 5px;
                background-color: $white-color;
                border-radius: 0 !important;
                text-align: center;
                line-height: 40px;
                color: $black-color;
                box-shadow: 0 2px 10px 0 #d8dde6;
                border: none;
                font: {
                    size: 17px;
                    weight: 600;
                };
                &.active, &:hover, &:focus {
                    background: $main-color;
                    color: $white-color;
                    box-shadow: unset;
                }
            }
        }
    }
}

/*================================================
Sidebar CSS
=================================================*/
.sidebar {
    .widget {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
        .widget-title {
            margin-bottom: 25px;
            position: relative;
            font: {
                size: 22px;
                weight: 600;
            };
            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 2px;
                width: 17px;
                height: 17px;
                background: $main-color;
                clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
                transition: $transition;
            }
        }
        &:hover, &:focus {
            .widget-title {
                &::before {
                    transform: rotate(180deg);
                }
            }
        }
        &.widget_search {
            background: $white-color;
            box-shadow: 0px 0px 29px 0px rgba(102,102,102,0.1);
            padding: 15px;

            form {
                position: relative;

                .form-control {
                    background: transparent;
                }
                button {
                    position: absolute;
                    right: 5px;
                    top: 0;
                    height: 100%;
                    border: none;
                    outline: 0;
                    box-shadow: unset;
                    background: transparent;
                    color: $main-color;
                    transition: $transition;

                    &:hover, &:focus {
                        color: $black-color;
                    }
                }
            }
        }
        &.widget_categories {
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;

                li {
                    position: relative;
                    border-top: 1px solid #eeeeee;
                    padding: {
                        top: 10px;
                        bottom: 10px;
                        left: 18px;
                    };
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        width: 10px;
                        height: 10px;
                        background: $main-color;
                        margin-top: -5px;
                        transition: $transition;
                    }
                    &:last-child {
                        border-bottom: 1px solid #eeeeee;
                    }
                    a {
                        color: $paragraph-color;
                        display: inline-block;

                        &:hover {
                            color: $main-color;
                        }
                    }
                    &:hover {
                        &::before {
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
        &.widget_recent_entries {
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;

                li {
                    position: relative;
                    padding-left: 100px;
                    margin-bottom: 15px;

                    a {
                        display: block;

                        img {
                            position: absolute;
                            left: 0;
                            top: 3px;
                            height: 65px;
                        }
                    }
                    h5 {
                        line-height: 22px;
                        margin-bottom: 3px;
                        font: {
                            size: 16px;
                            weight: 600;
                        };
                        a {
                            color: $black-color;
                            display: inline-block;

                            &:hover, &:focus {
                                color: $main-color;
                            }
                        }
                    }
                    p {
                        font-size: 14px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        &.widget_tag_cloud {
            .tagcloud {
                a {
                    border: 1px dashed #eeeeee;
                    padding: 6px 20px;
                    margin-top: 6px;
                    color: $paragraph-color;
                    display: inline-block;
                    margin-right: 6px;
                    font: {
                        size: 14px !important;
                        weight: 600;
                    };
                    &:hover {
                        background: $main-color;
                        color: $white-color;
                        border-color: $main-color;
                    }
                }
            }
        }
        &.widget_archive {
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;

                li {
                    position: relative;
                    border-top: 1px solid #eeeeee;
                    padding: {
                        top: 10px;
                        bottom: 10px;
                        left: 18px;
                    };
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        width: 10px;
                        height: 10px;
                        background: $main-color;
                        margin-top: -5px;
                        transition: $transition;
                    }
                    &:last-child {
                        border-bottom: 1px solid #eeeeee;
                    }
                    a {
                        color: $paragraph-color;
                        display: inline-block;

                        &:hover {
                            color: $main-color;
                        }
                    }
                    &:hover {
                        &::before {
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Blog Details CSS
=================================================*/
.blog-details {
    .post-image {
        margin-bottom: 35px;
    }
    h3 {
        font: {
            size: 22px;
            weight: 600;
        };
        margin: {
            top: 5px;
            bottom: 15px;
        };
    }
    .blog-meta {
        ul {
            padding: 0;
            margin: 20px 0;
            list-style-type: none;

            li {
                display: inline-block;
                position: relative;
                color: $paragraph-color;
                font: {
                    size: 14px;
                };
                margin: {
                    left: 12px;
                    right: 12px;
                };
                i {
                    margin-right: 5px;
                    color: $main-color;
                }
                &::before {
                    width: 1px;
                    height: 14px;
                    background: $paragraph-color;
                    left: -13px;
                    top: 50%;
                    position: absolute;
                    content: '';
                    margin-top: -6px;
                    transform: rotate(11deg);
                }
                a {
                    display: inline-block;
                    color: $paragraph-color;

                    &:hover, &:focus {
                        color: $main-color;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
                &:first-child {
                    margin-left: 0;

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
    p {
        margin: {
            top: 15px;
            bottom: 0;
        };
        &:last-child {
            margin-bottom: 0;
        }
    }
    blockquote, .blockquote {
        background: #f6f6f6 none repeat scroll 0 0;
        padding: 35px 20px 35px 100px;
        position: relative;
        border-left: 5px solid $main-color;
        margin: {
            top: 25px;
            bottom: 25px;
        };
        &::before {
            font-family: IcoFont!important;
            position: absolute;
            content: "\efcd";
            left: 25px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 55px;
            color: $main-color;
        }
        p {
            color: $black-color;
            margin: 0;
            font: {
                size: 18px;
                weight: 600;
            };
        }
    }
}
.post-tag-media {
    background-color: #f5f7fb;
    padding: 20px;
    margin: {
        top: 25px;
        bottom: 35px;
    };
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            display: inline-block;

            span {
                margin-right: 5px;
                display: inline-block;
                font: {
                    weight: 600;
                };
            }
            a {
                color: $paragraph-color;
                margin-right: 4px;
                display: inline-block;

                &:hover, &:focus {
                    color: $main-color;
                }
            }
        }
        &.social-share {
            text-align: right;

            li {
                span {
                    margin-right: 0;
                }
                a {
                    margin: {
                        right: 0;
                        left: 6px;
                    };
                }
            }
        }
    }
}
.comments-area {
    .comments-title, .comment-reply-title {
        margin-bottom: 25px;
        font: {
            size: 22px;
            weight: 600;
        };
    }
    .comment-reply-title {
        margin-bottom: 8px;
    }
    ol, ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            ol, ul {
                li {
                    margin: {
                        top: 15px;
                        left: 35px;
                        bottom: 15px;
                    }
                }
            }
            .comment-body {
                background: #f7f7ff;
                padding: 25px 20px;
                position: relative;

                .comment-meta {
                    position: relative;
                    padding: {
                        left: 85px;
                        top: 8px;
                    };
                    .comment-author {
                        img {
                            position: absolute;
                            left: 0;
                            top: 0;
                            border-radius: 50%;
                            max-width: 68px;
                        }
                        .fn {
                            margin-bottom: 5px;
                            display: block;
                            font: {
                                size: 16px;
                                weight: 600;
                            };
                        }
                        .says {
                            display: none;
                        }
                    }
                    .comment-metadata {
                        a {
                            display: inline-block;
                            color: $paragraph-color;
                            font-size: 14px;
                            
                            &:hover, &:focus {
                                color: $main-color;
                            }
                        }
                    }
                }
                .comment-content {
                    margin: {
                        top: 25px;
                    }
                }
                .reply {
                    position: absolute;
                    right: 30px;
                    top: 30px;

                    a {
                        display: inline-block;
                        background: $black-color;
                        padding: 5px 15px;
                        color: $white-color;

                        &:hover, &:focus {
                            background: $main-color;
                        }
                    }
                }
            }
        }
    }
    .comment-respond {
        margin-top: 30px;

        .comment-notes {
            font-size: 14px;
            margin-bottom: 20px;
        }
        .comment-form-comment, .comment-form-author, .comment-form-email, .comment-form-url {
            input, textarea {
                display: block;
                width: 100%;
                height: 45px;
                outline: 0 !important;
                box-shadow: unset !important;
                border: 1px solid #eeeeee;
                padding: 15px;
                border-radius: 3px;
                transition: $transition;

                &:focus {
                    border-color: $main-color;
                }
            }
            textarea {
                height: auto;
            }
        }
        .form-submit {
            input {
                display: inline-block;
                background: $main-color;
                border: none;
                color: $white-color;
                padding: 8px 30px;
                text-transform: uppercase;
                font-weight: 600;
                outline: 0 !important;
                margin-top: 5px;
                transition: $transition;

                &:hover, &:focus {
                    background: $black-color;
                    box-shadow: 0 13px 27px 0 rgba(255, 82, 2, 0.11);
                }
            }
        }
    }
}

/*================================================
404 Error CSS
=================================================*/
.error-content {
    margin: 0 auto;
    max-width: 650px;
    
    .search-form {
        position: relative;
        margin-top: 45px;

        .search-field {
            display: block;
            width: 100%;
            height: 50px;
            outline: 0;
            box-shadow: unset;
            padding-left: 15px;
            text-transform: capitalize;
            font-weight: 600;
            border: 1px solid #eeeeee;
        }
        .search-submit {
            position: absolute;
            right: 0;
            bottom: 0;
            height: 50px;
            width: 110px;
            outline: 0;
            box-shadow: unset;
            border: none;
            background: $main-color;
            color: $white-color;
            font-weight: 600;
            transition: $transition;

            &:hover, &:focus {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
}

/*================================================
Footer CSS
=================================================*/
.footer-area {
    background-color: $black-color;
    padding: {
        top: 30px;
        bottom: 30px;
    };
    p {
        color: $white-color;
        font: {
            family: $font-family2;
            weight: 500;
        }
        .icofont-heart {
            color: red;
        }
        a {
            display: inline-block;
            color: $white-color;
            font-weight: 600;

            &:hover, &:focus {
                color: $main-color;
            }
        }
    }
    ul {
        text-align: right;
        padding: 0;
        list-style-type: none;
        margin: 0;

        li {
            display: inline-block;
            margin-left: 5px;

            a {
                height: 32px;
                width: 32px;
                text-align: center;
                line-height: 32px;
                transition: $transition;
                color: $black-color;
                border-radius: 50%;
                display: block;
                background: $white-color;
                font-size: 14px;

                &:hover, &:focus {
                    transform: translateY(-5px);
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}

/*==============================
Sidebar Eaxmple Demo CSS
==============================*/
.demo-modal-panel {
    position: fixed;
    right: 0;
    top: 40%;
    transform: translateY(-40%);
    z-index: 99998;

    .sidebar-demo-control {
        display: inline-block;
        border: none;
        background-color: $main-color;
        color: $white-color;
        padding: 0 0;
        letter-spacing: 1px;
        box-shadow: rgba(0, 0, 0, 0.15) 0 0 25px;
        padding: 12px 0 10px;
        transition: $transition;
        cursor: pointer;
        text-transform: uppercase;
        border-radius: 5px 0 0 5px;
        font: {
            weight: 600;
            size: 13px;
        };
        span {
            writing-mode: vertical-rl;
            text-orientation: upright;
            padding: {
                left: 5px;
                right: 5px;
            };
        }
        &:hover {
            color: $white-color;
            background-color: $black-color;
        }
    }
}
.example-demo-modal {
    position: fixed;
    overflow: hidden;
    right: 0;
    top: 0;
    z-index: 99999;
    transition: .9s;
    opacity: 0;
    visibility: hidden;
    border-left: 1px solid #eeeeee;
    right: -100%;

    .inner {
        width: 450px;
        height: 100vh;
        overflow-y: scroll;
        background-color: $white-color;
        box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
        padding: 90px 30px 30px 30px;

        ul {
            display: flex;
            flex-wrap: wrap;
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            margin: {
                left: -10px;
                right: -10px;
                bottom: -20px;
            };
            li {
                flex: 0 0 50%;
                max-width: 50%;
                padding: {
                    left: 10px;
                    right: 10px;
                    bottom: 20px;
                };
                .single-demo {
                    position: relative;
                    text-align: center;
                    border-radius: 5px;

                    img {
                        border-radius: 5px;
                        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
                        border: 5px solid $white-color;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: $main-color;
                        transition: $transition;
                        opacity: 0;
                        visibility: hidden;
                        border-radius: 5px;
                    }
                    span {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        z-index: 1;
                        color: $white-color;
                        text-transform: capitalize;
                        transition: $transition;
                        opacity: 0;
                        visibility: hidden;
                        margin-top: 15px;
                        font: {
                            size: 16px;
                            weight: 600;
                        };
                    }
                    .link-btn {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        display: block;
                        z-index: 3;
                    }
                    &:hover {
                        &::before {
                            opacity: 1;
                            visibility: visible;
                        }
                        span {
                            opacity: 1;
                            visibility: visible;
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
    .header-title {
        position: absolute;
        background-color: $white-color;
        top: -1px;
        left: 0;
        right: 0;
        z-index: 5;
        border-bottom: 1px solid #eeeeee;
        padding: {
            top: 18px;
            bottom: 15px;
            left: 30px;
            right: 30px;
        };
        .example-demo-modal-control {
            position: absolute;
            right: 25px;
            top: 15px;
            font-size: 20px;
            color: $black-color;
            transition: $transition;
            background-color: transparent;
            border: none;
            padding: 0;
            display: inline-block;
    
            &:hover {
                color: $main-color;
            }
        }
        .title {
            color: $black-color;
            margin-bottom: 0;
            font: {
                size: 18px;
                weight: 600;
            };
        }
    }
    &.active {
        right: 0;
        opacity: 1;
        visibility: visible;
    }
}